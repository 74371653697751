import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Divider, Heading, IconButton, Link, Tag, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Tag as TagIcon, File as FileIcon, Download as DownloadIcon } from "react-feather";
import Style from "./datasetView.module.css";
import { IDatasetBrief } from "@util/interfaces";
import { getDatasetStatusColor, getDatasetStatusText } from "@util/misc";
import { ShowMore } from "@components";
import axios from "axios";
import fileDownload from "js-file-download";

export const ClientView = (props: { datasets: Array<IDatasetBrief>; getContentView: any }) => {
    const [chosenDataset, setChosenDataset] = useState(0);
    const [downloadAllLoading, setDownloadAllLoading] = useState(false);
    const [downloadAllError, setDownloadAllError] = useState(false);

    const downloadAllFiles = () => {
        const urls = props.datasets[chosenDataset].file_set.map((file) => file.url);
        const downloadindex = 0;

        setDownloadAllLoading(true);

        // while (downloadindex < urls.length) {
        axios
            .get(urls[downloadindex], { responseType: "blob" })
            .then((res) => {
                // downloadindex = downloadindex + 1;
                fileDownload(res.data, props.datasets[chosenDataset].file_set[downloadindex].filename);
                // console.log(res);
            })
            .catch((err) => {
                setDownloadAllError(true);
                console.log(err);
            });
        // }

        setDownloadAllLoading(false);
    };

    if (props.datasets.length === 0) {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Text>برگزارکننده هنوز دیتاستی برای این چالش قرار نداده است.</Text>
            </Box>
        );
    } else if (props.datasets.length === 1) {
        return (
            <>
                <ShowMore height={320} shadowColor={useColorModeValue("rgb(247,247,247)", "rgb(20,24,29)")} speed={500}>
                    <div className="flex flex-row justify-start" id={"show_more_contents"}>
                        <div className={`w-full mx-4 ${Style.container}`}>
                            <div className="mt-4 flex flex-col w-full">
                                <div className="flex flex-row items-center">
                                    <Heading as="h2" fontSize="2xl" fontWeight="700" display="inline" mb={0}>
                                        {props.datasets[0].title}
                                    </Heading>
                                    <Tooltip hasArrow label="نسخه دیتاست">
                                        <Tag className="mr-4">
                                            <TagIcon size={16} className="ml-2" color="var(--chakra-colors-green-500)" />
                                            {props.datasets[0].version}
                                        </Tag>
                                    </Tooltip>
                                    <Tooltip hasArrow label="وضعیت دیتاست">
                                        <Badge colorScheme={getDatasetStatusColor(props.datasets[0].status)} className="mr-4">
                                            {getDatasetStatusText(props.datasets[0].status)}
                                        </Badge>
                                    </Tooltip>
                                </div>
                                {props.datasets[0].subtitle && (
                                    <Heading as="h3" fontSize="md" fontWeight="300" mt={3}>
                                        {props.datasets[0].subtitle}
                                    </Heading>
                                )}
                            </div>
                            <Divider my="16px" />
                            <div>
                                {props.datasets[0].dataset_description && props.datasets[0].dataset_description.length !== 0 ? (
                                    props.getContentView(props.datasets[0].dataset_content_type, props.datasets[0].dataset_description)
                                ) : (
                                    <p>توضیحی وارد نشده است.</p>
                                )}
                            </div>
                            <Divider my="16px" />
                        </div>
                    </div>
                </ShowMore>
                <Divider my="16px" />
                <div className="mt-4">
                    <Heading as="h3" fontSize="xl" fontWeight="700" mb={2}>
                        دانلودها
                    </Heading>
                    <div className="border mt-3 rounded flex flex-col">
                        {/* {props.datasets[chosenDataset].file_set && (
                            <Button
                                size={"sm"}
                                variant="solid"
                                isLoading={downloadAllLoading}
                                colorScheme={useColorModeValue("RoboEpics.green", "RoboEpics.azure")}
                                leftIcon={<DownloadIcon size={16} />}
                                m={4}
                                mr="auto"
                                onClick={() => {
                                    downloadAllFiles();
                                }}
                            >
                                دریافت همه فایل‌ها
                            </Button>
                        )} */}
                        {(props.datasets[chosenDataset].file_set === undefined ||
                            props.datasets[chosenDataset].file_set === null ||
                            props.datasets[chosenDataset].file_set.length === 0) && <p>شما دسترسی به فایل های این دیتاست ندارید.</p>}
                        {props.datasets[0].file_set?.map((fileEl, index) => {
                            return (
                                <Box
                                    className={`w-full flex flex-row items-center my-1 px-3 py-1 ${
                                        index === props.datasets[0].file_set.length - 1 ? "" : "border-b"
                                    }`}
                                >
                                    {/* icon */}
                                    <Box>
                                        <FileIcon size={16} className="ml-3" />
                                    </Box>

                                    {/* title */}
                                    <Box className="flex-grow">
                                        <Box>
                                            <Link href={fileEl.url} target="_blank">
                                                <span>{fileEl.filename}</span>
                                            </Link>
                                        </Box>
                                    </Box>

                                    {/* buttons */}
                                    <Box>
                                        <a href={fileEl.url} target="_blank">
                                            <IconButton
                                                variant="ghost"
                                                size="sm"
                                                aria-label={`Download '${fileEl.filename}'`}
                                                icon={<DownloadIcon size={18} />}
                                            />
                                        </a>
                                    </Box>
                                </Box>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    } else if (props.datasets.length > 1) {
        return (
            <Box className={`flex flex-col mt-4`}>
                <Box
                    className={`flex flex-row w-full mx-4 mb-4 justify-start items-center self-center`}
                    overflowY={"hidden"}
                    h={40}
                    overflowX={"auto"}
                >
                    {props.datasets.map((dataset: IDatasetBrief, index: number) => {
                        return (
                            <Box
                                key={`.dataset-card-${index}`}
                                className={`flex flex-col border rounded-lg pt-4 px-4 w-full mx-4 cursor-pointer ${Style.dataset_card} ${
                                    index === chosenDataset
                                        ? useColorModeValue(Style.chosen_dataset_card_light, Style.chosen_dataset_card_dark)
                                        : ""
                                }`}
                                maxW={"3xs"}
                                minW={"3xs"}
                                minH={"28"}
                                maxH={"28"}
                                boxShadow={`0 2px 9px -1px #64dfdf, 0 2px 4px -1px ${useColorModeValue(
                                    "rgba(0, 0, 0, 0.06)",
                                    "rgba(255, 255, 255, 0.06)",
                                )}`}
                                _hover={{
                                    boxShadow: `0 3px 14px -1px #64dfdf,0 3px 5px -2px ${useColorModeValue(
                                        "rgba(0, 0, 0, 0.05)",
                                        "rgba(255, 255, 255, 0.05)",
                                    )}`,
                                }}
                                onClick={() => {
                                    setChosenDataset(index);
                                }}
                            >
                                <Box className="flex flex-row w-full justify-between items-start">
                                    <Heading isTruncated size="sm">
                                        {dataset.title}
                                    </Heading>
                                    <Badge colorScheme={getDatasetStatusColor(dataset.status)} className="mr-4">
                                        {getDatasetStatusText(dataset.status)}
                                    </Badge>
                                </Box>
                                <Divider mb={"0.5em"} />
                                <Text fontSize={"14px"} h="40px" noOfLines={2}>
                                    {dataset.subtitle}
                                </Text>
                            </Box>
                        );
                    })}
                </Box>
                <ShowMore height={320} shadowColor={useColorModeValue("rgb(247,247,247)", "rgb(20,24,29)")} speed={500}>
                    <div className="flex flex-row justify-start" id={"show_more_contents"}>
                        <div className={`w-full mx-4 ${Style.container}`}>
                            <div className="mt-4 flex flex-col w-full">
                                <div className="flex flex-row items-center">
                                    <Heading as="h2" fontSize="2xl" fontWeight="700" display="inline" mb={0}>
                                        {props.datasets[chosenDataset].title}
                                    </Heading>
                                    <Tooltip hasArrow label="نسخه دیتاست">
                                        <Tag className="mr-4">
                                            <TagIcon size={16} className="ml-2" color="var(--chakra-colors-green-500)" />
                                            {props.datasets[chosenDataset].version}
                                        </Tag>
                                    </Tooltip>
                                    <Tooltip hasArrow label="وضعیت دیتاست">
                                        <Badge colorScheme={getDatasetStatusColor(props.datasets[0].status)} className="mr-4">
                                            {getDatasetStatusText(props.datasets[0].status)}
                                        </Badge>
                                    </Tooltip>
                                </div>
                                {props.datasets[chosenDataset].subtitle && (
                                    <Heading as="h3" fontSize="md" fontWeight="300" mt={3}>
                                        {props.datasets[chosenDataset].subtitle}
                                    </Heading>
                                )}
                            </div>
                            <Divider my="16px" />
                            <div>
                                {props.datasets[chosenDataset].dataset_description &&
                                props.datasets[chosenDataset].dataset_description.length !== 0 ? (
                                    props.getContentView(
                                        props.datasets[chosenDataset].dataset_content_type,
                                        props.datasets[chosenDataset].dataset_description,
                                    )
                                ) : (
                                    <p>توضیحی وارد نشده است.</p>
                                )}
                            </div>
                            <Divider my="16px" />
                        </div>
                    </div>
                </ShowMore>
                <div className="mt-4">
                    <Heading as="h3" fontSize="xl" fontWeight="700" mb={2}>
                        دانلودها
                    </Heading>
                    <div className="border mt-3 rounded flex flex-col">
                        {/* {props.datasets[chosenDataset].file_set && (
                            <Button
                                size={"sm"}
                                variant="solid"
                                isLoading={downloadAllLoading}
                                colorScheme={useColorModeValue("RoboEpics.green", "RoboEpics.azure")}
                                leftIcon={<DownloadIcon size={16} />}
                                m={4}
                                mr="auto"
                                onClick={() => {
                                    downloadAllFiles();
                                }}
                            >
                                دریافت همه فایل‌ها
                            </Button>
                        )} */}
                        {(props.datasets[chosenDataset].file_set === undefined ||
                            props.datasets[chosenDataset].file_set === null ||
                            props.datasets[chosenDataset].file_set.length === 0) && <p>شما دسترسی به فایل های این دیتاست ندارید.</p>}
                        {props.datasets[chosenDataset].file_set?.map((fileEl, index) => {
                            return (
                                <Box
                                    className={`w-full flex flex-row items-center my-1 px-3 py-1 ${
                                        index === props.datasets[chosenDataset].file_set.length - 1 ? "" : "border-b"
                                    }`}
                                >
                                    {/* icon */}
                                    <Box>
                                        <FileIcon size={16} className="ml-3" />
                                    </Box>

                                    {/* title */}
                                    <Box className="flex-grow">
                                        <Box>
                                            <Link href={fileEl.url} target="_blank">
                                                <span>{fileEl.filename}</span>
                                            </Link>
                                        </Box>
                                    </Box>

                                    {/* buttons */}
                                    <Box>
                                        <a href={fileEl.url} target="_blank">
                                            <IconButton
                                                variant="ghost"
                                                size="sm"
                                                aria-label={`Download '${fileEl.filename}'`}
                                                icon={<DownloadIcon size={18} />}
                                            />
                                        </a>
                                    </Box>
                                </Box>
                            );
                        })}
                    </div>
                </div>
            </Box>
        );
    } else {
        return (
            <Box className={`flex flex-col items-center mt-8`}>
                <Text>
                    This is an Error <br />
                    برگزارکننده هنوز دیتاستی برای این چالش قرار نداده است.
                </Text>
            </Box>
        );
    }
};
